body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
iframe#webpack-dev-server-client-overlay{display:none!important}
body,
div,
input,
label,
span,
svg,
h1,
h2,
h3 {
    font-family:
        'Inter',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif !important;
}

div,
a {
    box-sizing: border-box;
}

h2,
h3,
h4,
p {
    margin: 0;
}

h3,
h4 {
    font-weight: 500;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
